import React from 'react';
import styled from 'styled-components'
import { Link, useLocation } from "react-router-dom";
import Icon from "../SVG/Icons";
import { useAuthUser } from '../Session';
import SignOutButton from '../SignOut';
// import NavBar from './NavBar';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Img from '../../components/Img';

import { useDelayedRender } from '../../utils/delayed.js'

import "@fortawesome/fontawesome-free/css/all.min.css";

import Logo from '../../assets/images/logo_small_icon_only.png'

const Navigation = styled.nav`
    display:flex;
    justify-content: space-between;
    height:69px;
    width: 100%;
    overflow: hidden;
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0,0,0,var(--bg-opacity));
`
const NavBarRight = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1.5rem;
    right:-20px;
    width: 100%;
    line-height:inherit
`

const NavBarLeft = styled.div`
    margin-left: 1rem;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
`

const A = styled(Link)`
    display: inline-block;
    margin-top:0;
    margin-right:1rem;
    color:white;
    text-decoration:none;
    &:hover {
    text-decoration:underline;
}
`

const Text = styled.div`
    font-size: .875rem;
`

function LinkTo({ label, to, activeOnlyWhenExact }) {
  return (
    <A to={to}>{label}</A>
  );
}

function Header(props) {
  const authUser = useAuthUser();
  const location = useLocation();
  const DelayedRender = ({ delay, children }) => useDelayedRender(delay)(() => children);

  return (
    <>

      <header>
        <Navigation className="navBar" >
          <NavBarLeft className="navBar__left">
            <Link to={ROUTES.LANDING}>
              <Img src={Logo} style={{ width: 55 + 'px' }} alt="Last Light Production Logo" />
            </Link>
          </NavBarLeft>
          <NavBarRight width={authUser} className="navBar__right">
            <Text>
              {!authUser ? <NavigationNonAuth /> : <NavigationAuth authUser={authUser} />}

            </Text>
          </NavBarRight>
        </Navigation>
      </header>
    </>
  );
};

const NavigationAuth = ({ authUser }) => (
  <>
    <LinkTo to={ROUTES.COMPANY} label="Company" />
    {
      !!authUser.roles[ROLES.ADMIN] && (
        <>
          <LinkTo to={ROUTES.SIGN_IN} label="Login" />
          <LinkTo to={ROUTES.EVENTSCHEDULE} label="Tourplaner" />
          <LinkTo to={ROUTES.ADMIN} label="User Dashboard" />
        </>
      )
    }
    <SignOutButton />
  </>
);

const NavigationNonAuth = () => (
  <>

    <LinkTo to="/impressum" label="Imprint" />
  </>
);

export default Header;