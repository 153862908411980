import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import FontFaceObserver from 'fontfaceobserver';
import Firebase, { FirebaseContext } from './components/Firebase';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyle } from './styles'

// Import root app
import App from "./containers/App";

// Import Language Provider
import { LanguageProvider } from './containers/LanguageProvider';

// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Inter', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const MOUNT_NODE = document.getElementById('app');

const ConnectedApp = () => (
  <HelmetProvider>
    <BrowserRouter>
      <FirebaseContext.Provider value={new Firebase()}>
        <LanguageProvider>
          <GlobalStyle />
          <App />
        </LanguageProvider>
      </FirebaseContext.Provider>
    </BrowserRouter>
  </HelmetProvider>
);

const render = () => {
  ReactDOM.render(<ConnectedApp />, MOUNT_NODE);
};

export default App;
if (module.hot) {
  // Hot reloadable React components
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}
render();
// Chunked polyfill for browsers without Intl support

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}