import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { FirebaseContext } from "../Firebase";
import useAuthUser from "./useAuthUser";

const withAuthorization = condition => Component => {
    function WithAuthorization(props) {
        const firebase = useContext(FirebaseContext);
        const user = useAuthUser();
        const navigate = useNavigate();

        useEffect(() => {
            const releaseAuthListener = firebase.onAuthUserListener(
                authUser => {
                    if (!condition(authUser)) {
                        navigate(ROUTES.SIGN_IN);
                    }
                },
                () => {
                    navigate(ROUTES.SIGN_IN);
                }
            );

            return () => releaseAuthListener();
        }, [firebase, navigate]);

        return condition(user) && <Component {...props} />;
    }

    return WithAuthorization;
};

export default withAuthorization;