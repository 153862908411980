import { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../Firebase";

function useAuthUser() {
    const [authUser, setAuthUser] = useState(
        JSON.parse(localStorage.getItem("authUser"))
    );
    const firebase = useContext(FirebaseContext);

    useEffect(() => {
        //console.log("useAuthUser loaded");
        const releaseAuthListener = firebase.onAuthUserListener(
            authUser => {
                firebase.auth.currentUser.getIdToken().then(function (idToken) {
                    authUser.token = idToken;
                    localStorage.setItem("authUser", JSON.stringify(authUser));
                    setAuthUser(authUser);
                });

            },
            () => {
                localStorage.removeItem("authUser");
                setAuthUser(null);
            }
        );

        return () => releaseAuthListener();
    }, []);

    return authUser;
}

export default useAuthUser;