export const LANDING = '/';
export const SIGN_UP = '/registration';
export const SIGN_IN = '/login';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const IMPRINT = '/impressum';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';

export const COMPANY = '/company';
export const COMPANY_DASHBOARD = '';
export const COMPANY_CREATE = 'create';
export const COMPANY_DETAILS = ':id';

export const EVENTSCHEDULE = '/schedule';
export const EVENTSCHEDULE_DASHBOARD = '';
export const EVENTSCHEDULE_CREATE = 'create';
export const EVENTSCHEDULE_DETAILS = ':id';