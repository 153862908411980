import React from 'react';
import styled from 'styled-components'
import { withFirebase } from '../Firebase';

const A = styled.a`
    display: inline-block;
    margin-top:0;
    margin-right:1rem;
    color:white;
    text-decoration:none;
    cursor:pointer;
    &:hover {
        text-decoration:underline;
    }
`

const SignOutButton = ({ firebase }) => (
    <A onClick={firebase.doSignOut}>
        Log Out
    </A>
);

export default withFirebase(SignOutButton);