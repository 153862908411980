import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    height: 100%;
    box-sizing: border-box;
}
html {
  touch-action: manipulation;
  font-feature-settings: "case" 1,"rlig" 1,"calt" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  body,html {
    font-family: "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    line-height: 1.5;
    margin:0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
  blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
    margin: 0;
}

.font-bold {
    font-weight:bold;
}
`