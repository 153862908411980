/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import { Container } from '@material-ui/core';

import HomePage from '../HomePage/Loadable';
import LandingPage from '../LandingPage/Loadable';
import ImprintPage from '../ImprintPage/Loadable';
import NotFoundPage from '../NotFoundPage/Loadable';
import SignInPage from '../SignInPage/Loadable';
import SignUpPage from '../SignUpPage/Loadable';
import PasswordForgetPage from '../PasswordForgetPage/Loadable'

import CompanyPage from '../Company/Loadable'
import CompanyDashboardPage from '../Company/CompanyDashboardPage/Loadable'
import CompanyCreatePage from '../Company/CompanyCreatePage/Loadable'
import CompanyDetailsPage from '../Company/CompanyDetailsPage/Loadable'

import ToursPage from '../ToursPage/Loadable'
import TourDashboardPage from '../ToursPage/TourDashboardPage/Loadable'
import TourCreatePage from '../ToursPage/TourCreatePage/Loadable'
import TourDetailsPage from '../ToursPage/TourDetailsPage/Loadable'

import Header from '../../components/Header';
// import Footer from 'components/Footer';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../../components/Session';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#8e8e8e',
    },
  },
});
const UpdatedContainer = styled(Container)`
    height: calc(100vh - 85px);
`
const App = () => (
  <>
    <Helmet
      titleTemplate="%s - Last Light Production - Production &amp; Tour Services"
      defaultTitle="Last Light Production"
    >
      <meta name="description" content="Last Light Production - Production &amp; Tour Services - Wir machen es für euch fertig!" />
      <meta property="og:url" content="https://www.last-light.de/" />
      <meta property="og:title" content="Last Light Production - Production &amp; Tour Services" />
      <meta property="og:description" content="Last Light Production - Production &amp; Tour Services - Wir machen es für euch fertig!" />
      <link rel="canonical" href="https://www.last-light.de/" />
    </Helmet>
    <Header />
    <ThemeProvider theme={theme}>
      <UpdatedContainer>
        <Routes>
          <Route path={ROUTES.HOME} element={<HomePage />} />
          <Route path={ROUTES.LANDING} element={<LandingPage />} />
          <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
          <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
          <Route path={ROUTES.PASSWORD_FORGET} element={<PasswordForgetPage />} />
          <Route path={ROUTES.IMPRINT} element={<ImprintPage />} />
          <Route path={ROUTES.COMPANY} element={<CompanyPage />}>
            <Route path={ROUTES.COMPANY_DASHBOARD} element={<CompanyDashboardPage />} />
            <Route path={ROUTES.COMPANY_CREATE} element={<CompanyCreatePage />} />
            <Route path={ROUTES.COMPANY_DETAILS} element={<CompanyDetailsPage />} />
          </Route>
          <Route path={ROUTES.EVENTSCHEDULE} element={<ToursPage />}>
            <Route path={ROUTES.EVENTSCHEDULE_DASHBOARD} element={<TourDashboardPage />} />
            <Route path={ROUTES.EVENTSCHEDULE_CREATE} element={<TourCreatePage />} />
            <Route path={ROUTES.EVENTSCHEDULE_DETAILS} element={<TourDetailsPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </UpdatedContainer>
    </ThemeProvider>
  </>
);

export default withAuthentication(App);