import React from "react";
import { IntlProvider } from "react-intl";

const enTranslation = import('../../translations/en.json');
const deTranslation = import('../../translations/de.json');

const Context = React.createContext();

class LanguageProvider extends React.Component {
    constructor(...args) {
        super(...args);

        this.switchToEnglish = () =>
            this.setState({ locale: "en", messages: enTranslation });

        this.switchToDeutsch = () =>
            this.setState({ locale: "de", messages: deTranslation });

        // pass everything in state to avoid creating object inside render method (like explained in the documentation)
        this.state = {
            locale: "en",
            messages: enTranslation,
            switchToEnglish: this.switchToEnglish,
            switchToDeutsch: this.switchToDeutsch
        };
    }

    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;
        return (
            <Context.Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale="en"
                >
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export { LanguageProvider, Context as IntlContext };